const CorrectURL = ( link: string ): string => {
    try
    {
        const url = new URL( link )
        const paramsObject: Record<string, string> = {}
        const entriesArray = Array.from( url.searchParams.entries() )
        for ( const [ key, value ] of entriesArray )
        {
            paramsObject[ key ] = value
        }

        url.search = ""
        url.pathname += Object.keys( paramsObject ).length > 0 ? JSON.stringify( paramsObject ) : ""
        return url.toString()
    } catch {
        return ""
    }
}


export const cleanLink = ( link: string ): string => {
    if ( link?.endsWith( '#/' ) )
    {
        return link?.slice( 0, -2 )
    } else if ( link?.endsWith( '#' ) && link !== "#" )
    {
        return link?.slice( 0, -1 )
    }
    return link
}

export const removeslash = ( link: string ): string => {
    if ( link?.startsWith( '/' ) )
    {
        return link?.slice( 1 )
    }
    return link
}

export const prepareLink = ( link: string, baseUrl?: string ): string => {
    if ( link?.startsWith( "#" ) || link?.startsWith( "/" ) || link === "javascript:;" || link?.startsWith( 'http' ) || link?.startsWith( "mailto:" ) )
    {
        return link
    }
    else
    {
        return CorrectURL( ( baseUrl ? baseUrl + "/" : process.env.NEXT_PUBLIC_BASE_URL ) + removeslash( cleanLink( link ) ) )
    }
}

export const convertAttributesToJSX = ( attributes: Record<string, string> ): Record<string, any> => {
    const jsxAttributes: Record<string, any> = {}

    for ( const [ name, value ] of Object.entries( attributes ) )
    {
        let jsxAttributeName = name

        if ( jsxAttributeName === 'class' )
        {
            jsxAttributeName = 'className'
        }

        if ( jsxAttributeName === 'style' )
        {
            const styleObject: Record<string, any> = {}
            const styleItems = value.split( ';' )
            styleItems.forEach( ( styleItem ) => {
                if ( styleItem.trim() !== '' )
                {
                    const colonIndex = styleItem.indexOf( ':' )
                    if ( colonIndex !== -1 )
                    {
                        const property = styleItem.slice( 0, colonIndex ).trim()
                        const val = styleItem.slice( colonIndex + 1 ).trim()
                        const jsxProperty = property.replace( /-(.)/g, ( _, match ) => match?.toUpperCase() )
                        styleObject[ jsxProperty ] = val
                    }
                }
            } )
            jsxAttributes[ jsxAttributeName ] = styleObject
        } else
        {
            jsxAttributes[ jsxAttributeName ] = value
        }
    }

    return jsxAttributes
}

export const parseResponsiveData = ( dataResponsive: string ) => {
    const parts = dataResponsive.split( ',' )
    const keys = [ 0, 640, 1200 ]
    const responsiveObject: { [ key: number ]: { slidesPerView: number, spaceBetween: number } } = {
        0: { slidesPerView: 1, spaceBetween: 0 },
        640: { slidesPerView: 1, spaceBetween: 0 },
        1200: { slidesPerView: 1, spaceBetween: 0 },
    }

    parts.forEach( ( part, i ) => {
        const [ count, space ] = part.split( '-' )
        responsiveObject[ keys[ i ] ] = {
            slidesPerView: parseInt( count ),
            spaceBetween: parseInt( space ),
        }
    } )

    return responsiveObject
}

export const generateRandomId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let id = ''
    const length = 10 // You can adjust the length as needed

    // Ensure the first character is a letter
    id += characters.charAt( Math.floor( Math.random() * 52 ) ) // 52 is the number of letters

    for ( let i = 1; i < length; i++ )
    {
        const randomIndex = Math.floor( Math.random() * characters.length )
        id += characters.charAt( randomIndex )
    }

    return id
}


import React from 'react';
import createJSX from './createJSX';
import { convertAttributesToJSX } from './helper';
import { Container, Grid } from '@mui/material';
import { ZohoFrom } from './zohoForm';
import { parse } from 'node-html-parser';

const CmsParser = (domElement) => {
    const tagName = domElement.tagName?.toLowerCase();
    const attributes = convertAttributesToJSX(domElement.attributes);
    const children = domElement.childNodes.map((childNode, i) => {
        if (childNode.nodeType === 3) {
            return childNode.textContent;
        } else if (childNode.nodeType === 1) {
            if (childNode.id?.startsWith('zoho-form-')) {
                const id = childNode.id.slice(10)
                return <Grid key={"zoho" + id} {...convertAttributesToJSX(childNode.attributes)}>
                    <ZohoFrom id={id} />
                </Grid>
            } else {
                return CmsParser(childNode);
            }
        }
        return null;
    });

    return createJSX(tagName, attributes, children)
};

const CmsPageParser = ({ id, children, sx = {}, useContainer = false }) => {
    const dom = parse(children, {
        lowerCaseTagName: false, // convert tag name to lower case (hurt performance heavily)
        script: true,            // retrieve content in <script> (hurt performance slightly)
        style: true,             // retrieve content in <style> (hurt performance slightly)
        pre: false               // retrieve content in <pre> (hurt performance slightly)
    });
    return (
        useContainer ?
            <Container maxWidth="xl">
                <Grid id={id} sx={sx}>
                    {dom ? CmsParser(dom) : null}
                </Grid>
            </Container>
            :
            <Grid id={id} sx={sx}>
                {dom ? CmsParser(dom) : null}
            </Grid>
    )

};

export { CmsPageParser, CmsParser };
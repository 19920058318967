import { GET_STORE_CONFIG, GET_RANDOM_PRODUCTS, CUSTOM_ATTRIBUTE_META_DATA, GET_BANNER_ITEMS, GET_CMS_BLOCK, SPECIAL_CATEGORIES_KEYS, SLIDERS } from "../graphql/queries";
import { getClient } from '../Providers/graphqlClient'
import CustomMetas from '../components/custom-metas';
import Grid from '@mui/material/Grid';
import { Container } from "@mui/material";
import ProductCards from "../components/productCards/productCards";
import MainBanner from "../components/main-banner/mainBanner";
import { CmsPageParser } from "../components/CmsParser";

const Homepage = (props) => {
    const cmsID = props.imageGroup?.cmsBlocks?.items?.at(0)?.identifier
    return (
        <Grid className="homepage">
            <CustomMetas
                title={props.storeData.storeConfig.default_title} description={props.storeData.storeConfig.default_description}
                keywords={props.storeData.storeConfig.default_keywords} page_type="website" image={process.env.NEXT_PUBLIC_LOGO_BASE_URL + props.storeData.storeConfig.header_logo_src}
                path={'/'}
            />
            <MainBanner data={props.sliders} />
            <Container maxWidth="xl">
                <Grid className="homepage-content" sx={{ pt: 0, pb: 5, px: 0 }}>
                    {props.categoreis.map((item, i) =>
                        <ProductCards metaData={props.metaData} storeData={props.storeData.storeConfig} key={i}
                            products={item.data.productListByQuery.items} title={props.costumCategoriesLabels.filter((obj, index) => index === i)[0]?.toUpperCase()}
                            totalCount={item.data.productListByQuery.total_count} count={5} url={`specials/${props.costumCategoriesKeys.filter((obj, index) => index === i)[0]}.html `}
                        />
                    )}
                </Grid>
            </Container>
            <Grid mt={8}>
                <CmsPageParser key={cmsID} id={cmsID} >{props.imageGroup?.cmsBlocks?.items?.at(0)?.content}</CmsPageParser>
            </Grid>
        </Grid>
    );
}

export default Homepage;

export const getStaticProps = async ({ locale }) => {

    const client = getClient(locale)
    var costumCategoriesKeys
    var costumCategoriesLabels
    const { data: catKeys } = await client.query({
        query: SPECIAL_CATEGORIES_KEYS,
    })

    costumCategoriesKeys = [...catKeys.homePageSections.keys.map(item => item.key), ...catKeys.homePageSections.categories.map(item => item.category_id)]
    costumCategoriesLabels = [...catKeys.homePageSections.keys.map(item => item.label), ...catKeys.homePageSections.categories.map(item => item.label)]

    let requests = await Promise.all(
        costumCategoriesKeys.map(key => client.query({
            query: GET_RANDOM_PRODUCTS,
            variables: {
                currentPage: 1,
                pageSize: 20,
                key: key,
                project: "Frankenspalter"
            }
        }))
    )

    console.log("ddd");

    const [{ data: storeData }, { data: sliders }, { data: metaData }, { data: imageGroup }, { data: banner }] = await Promise.all([
        client.query({
            query: GET_STORE_CONFIG,
        }),
        // client.query({
        //     query: GET_BANNER_ITEMS,
        // }),
        client.query({
            query: SLIDERS,
            variables: {
                position: "cms_index_index.page-top"
            },
        }),
        client.query({
            query: CUSTOM_ATTRIBUTE_META_DATA,
        }),
        client.query({
            query: GET_CMS_BLOCK,
            variables: {
                id: "HomePage_imageGroup",
            }
        }),
        client.query({
            query: GET_CMS_BLOCK,
            variables: {
                id: "home_page_slider_pwa"
            },
        })
    ])

    return {
        props: {
            storeData,
            metaData,
            categoreis: requests,
            sliders: sliders,
            imageGroup,
            costumCategoriesLabels,
            costumCategoriesKeys,
            banner
        },
        revalidate: 10,
    }


}


import { useEffect } from 'react'

const Style = ( { src, children }: { src?: string, children?: any } ) => {
    useEffect( () => {
        const styleElement = document.createElement( 'style' )
        if ( children )
            styleElement.innerHTML = children?.children?.at( 0 )
        else if ( src )
        {
            styleElement.setAttribute( 'type', 'text/css' )
            styleElement.setAttribute( 'media', 'all' )
            styleElement.textContent = `
    @import url('${ src }');
  `
        }
        document.head.appendChild( styleElement )
    }, [] )

    return null
}

export default Style
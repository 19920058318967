import Link from 'next/link';
import React from 'react'
import { generateRandomId, parseResponsiveData, prepareLink } from './helper';
import Script from 'next/script';
import Style from './style';

const elName = (name) => name.replaceAll("-", "_") + "Var"

const tabsClick = (e) => {
    e.preventDefault();
    const parent = e?.target?.closest('.mgz-tabs-nav');
    const panel = e?.target?.closest('.mgz-tabs-tab-title');
    const tabId = panel?.querySelector('a').getAttribute('data-id');
    panel?.classList?.toggle("mgz-active");
    Array.from(parent.querySelectorAll(".mgz-active"))?.filter(element => element !== panel)?.forEach(element => {
        element.classList.remove("mgz-active");
    });
    const tabsContainer = parent?.closest('.mgz-tabs');
    const contentPanel = tabsContainer?.querySelector(tabId);
    contentPanel?.classList?.toggle("mgz-active");
    Array.from(tabsContainer?.querySelectorAll(".mgz-tabs-tab-content.mgz-active"))?.filter(element => element !== contentPanel)?.forEach(element => {
        element?.classList?.remove("mgz-active");
    });
}
const collapseClick = (e) => {
    e.preventDefault();
    const parent = e.target.closest('.mgz-block');
    const panel = e.target.closest('.mgz-panel');
    panel.classList.toggle("mgz-active");
    Array.from(parent.querySelectorAll(".mgz-active"))?.filter(e => e !== panel)?.map(element => {
        element.classList.remove("mgz-active");
    });
}

const createJSX = (tagName, attributes, children) => {
    if (typeof tagName === "undefined") tagName = "div"
    if (tagName === "div" && attributes['data-element'] && attributes['data-element'].startsWith('sfc:')) {
        tagName = "sfc"
        attributes['data-element'] = attributes['data-element'].slice(4)
    }

    let Extra = null
    let scriptBody = ''
    let found = false
    let varName = ""
    for (const attrName in attributes) {
        if (attrName.startsWith("on")) {
            if (!found) {
                found = true
                if (!attributes.id) attributes.id = generateRandomId()
                varName = elName(attributes.id)
                scriptBody = ``;
            }
            const eventName = attrName.slice(2);
            scriptBody += `document.addEventListener('${eventName}', function(event) {
                if (event.target && event.target.id === '${attributes.id}') {
                    const ${varName} = document.getElementById('${attributes.id}'); 
                    ${attributes[attrName].replaceAll('this', varName)}
                }
            });
`;
            delete attributes[attrName];
        }
    }
    if (found)
        Extra = <Script id={varName + "Script"} dangerouslySetInnerHTML={{ __html: scriptBody }} defer={true} strategy='afterInteractive' />;


    let jsxElement;
    const customStyles = {
        "& .swiper-button-prev:after, .swiper-button-next:after": { fontSize: "24px" },
        "& .swiper-button-next": {
            cursor: 'pointer',
            right: "-5px",
            color: '#999999 !important',
        },
        "& .swiper-button-prev": {
            cursor: 'pointer',
            color: '#999999 !important',
        },
    };

    switch (tagName) {
        case 'a':
            if (attributes.href.includes('#tab-')) {
                if (attributes['data-id']?.includes('tab-')) {
                    jsxElement = <Link {...attributes} onClick={e => tabsClick(e)} >{children}</Link>;
                } else {
                    jsxElement = <Link {...attributes} onClick={e => collapseClick(e)} >{children}</Link>;
                }
            } else {
                attributes.href = prepareLink(attributes.href, process.env.NEXT_PUBLIC_BACKEND_URL)
                try {
                    jsxElement = <Link {...attributes} >{children}</Link>;
                } catch (error) {
                    jsxElement = <Link href="/">{children}</Link>;
                }
            }
            break;
        case 'link':
            jsxElement = <Style src={attributes.href} />;
            break;
        case 'br':
            jsxElement = <br />;
            break;
        case 'body':
            jsxElement = <>{children}</>;
            break;
        case 'input':
            attributes.defaultValue = attributes.value
            delete attributes.value
            jsxElement = <input {...attributes} />;
            break;
        case 'hr':
            jsxElement = <hr {...attributes} />;
            break;
        case 'img':
            if (!attributes.alt) attributes.alt = ""
            attributes.src = prepareLink(attributes.src)
            jsxElement = <img {...attributes} />;
            break;
        case 'script':
            if (attributes.src) {
                jsxElement = <Script {...attributes} defer={true} strategy="afterInteractive" />
            } else if (!children?.at(0)?.includes('require')) {
                children = children[0].replace("document.addEventListener('readystatechange', function () {", "setTimeout(function () {")
                jsxElement = <Script {...attributes} defer={true} strategy='lazyOnload' >{children}</Script>
            }
            break;
        case 'meta':
            jsxElement = null
            break;
        case "style":
            jsxElement = <Style>{children}</Style>
            break;

        default:
            if (tagName === 'html' || tagName === 'body' || tagName === "head") tagName = "div"
            jsxElement = React.createElement(tagName || 'div', attributes, children);
            break;
    }
    return Extra ? <>{Extra}{jsxElement}</> : jsxElement
}

export default createJSX
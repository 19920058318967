import { useRef } from "react"

export const ZohoFrom = ( { id }: { id: string } ) =>
{
    return (
        <iframe
            id={ `JotFormIFrame-${ id }` }
            title="zoho" allowTransparency={ true } allowFullScreen={ true }
            src={ `https://form.jotform.com/${ id }` }
            style={ { width: "100%", height: "100%", border: "none" } } />
    )
}
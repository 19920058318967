import { Grid, Box, Button, useMediaQuery } from "@mui/material";
import { CmsPageParser } from "../CmsParser";
import { useRouter } from "next/router";
import Swiper from "../swiper"
import json2mq from "json2mq";

const MainBanner = ({ data }) => {
    const isMD = useMediaQuery(
        json2mq({
            minWidth: 900,
        }),
    );
    const router = useRouter()
    const customStyles = {
        "& .swiper-button-prev:after, .swiper-button-next:after": { fontSize: "32px" },
        "& .swiper-button-next": {
            cursor: 'pointer',
            right: "0px",
            color: '#999999 !important',
        },
        "& .swiper-button-prev": {
            cursor: 'pointer',
            left: "12px",
            color: '#999999 !important',
        },
        "--swiper-pagination-bottom": "40px",
        "--swiper-pagination-bullet-horizontal-gap": "8px",
        "--swiper-pagination-bullet-inactive-opacity": "0.4",
        ".swiper-pagination-bullet": {
            width: 12,
            height: 12,
            bgcolor: '#000',
            "&.swiper-pagination-bullet-active": {
                bgcolor: "#ffffffc7 !important"
            }
        }
    };
    const slider = data?.sliders?.at(0)

    const mobileHeight = 440
    const mobileZoom = 0.6
    const mobileImageHeight = 140

    return (
        slider ?
            <Swiper
                key={slider.id}
                sx={customStyles}
                navigation={true}
                Autoplay={false}
                pagination={true}
                loop={false}
                id="main-banner"
                effect={'fade'}
            >
                {slider.banners.sort((a, b) => (a.position - b.position)).map(banner => {
                    const [verAlign, horAlign] = (banner.alignment || "top-right").replace("middle", "center").replace("bottom", "end").replace("top", "left").split("-")
                    return (
                        <Box key={banner.id} sx={{ height: { xs: mobileHeight, md: 550 } }} position="relative" ds={console.log(banner.mobile_image)}>
                            <Box sx={{ height: { xs: mobileHeight - 25, md: 525 }, backgroundImage: { xs: `url(/media${banner.mobile_image})`, lg: `url(/media${banner.image})` }, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
                            </Box>
                            <Box position="absolute" className="animate" sx={{ width: "100%", height: "100%", left: 0, px: { xs: 2, lg: 6 } }}>
                                <Grid height="100%" sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: { xs: "center", sm: verAlign } }}>
                                    <Grid sx={{ "& p": { lineHeight: { xs: "32px", lg: "42px !important" } }, maxHeight: { xs: (mobileHeight - mobileImageHeight) - 40, md: "100%" }, flex: "1", width: { xs: "100%", md: "50%" }, display: "flex", flexDirection: "column", alignContent: "center", pt: 2, pb: { xs: 0, md: 8 }, px: { xs: 1, md: 4 }, my: { xs: 2, md: 0 }, justifyContent: { xs: "end", sm: verAlign }, overflow: "hidden" }}   >
                                        {
                                            banner.html_content || banner.description
                                                ?
                                                <CmsPageParser sx={{ " p": { marginBottom: "0px !important" }, color: "#fff", textAlign: { xs: "center", md: horAlign }, textShadow: "#000 1px 0 1px" }}>
                                                    {
                                                        isMD ?
                                                            banner.html_content || banner.description
                                                            :
                                                            EnSmall(banner.html_content || banner.description, mobileZoom)
                                                    }
                                                </CmsPageParser>
                                                :
                                                null
                                        }
                                        {
                                            banner.button_text
                                                ?
                                                <Box sx={{ width: "100%", textAlign: { xs: "center", md: horAlign } }}>
                                                    <Button variant="contained" sx={{
                                                        mt: 2, bgcolor: banner.button_color || "#404040", fontSize: { xs: 15, md: 18 }, width: "fit-content",
                                                        "&:hover": { color: banner.button_color || "#404040", bgcolor: "#fff" }
                                                    }} onClick={() => {
                                                        if (banner.url) {
                                                            if (banner.open_new_tab === 1) {
                                                                window.open(banner.url, "_blank");
                                                            } else {
                                                                router.push(banner.url)
                                                            }
                                                        }
                                                    }}>{banner.button_text}</Button>
                                                </Box>
                                                :
                                                null
                                        }
                                    </Grid>
                                    {
                                        banner.animated_image.slice(-1) !== "/"
                                            ?
                                            <Grid sx={{ flex: { xs: "unset", md: "1" }, width: { xs: "100%", md: "50%" }, height: { xs: mobileImageHeight, md: "100%" }, pt: { xs: 0, md: 2 }, position: "relative" }}>
                                                <Box sx={{
                                                    width: "100%", height: "100%",
                                                    backgroundImage: `url(/media${banner.animated_image})`,
                                                    backgroundPosition: { xs: "center bottom", md: "left bottom" },
                                                    backgroundSize: "contain",
                                                    backgroundRepeat: "no-repeat"
                                                }} />
                                            </Grid>
                                            :
                                            null
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    )
                }
                )}
            </Swiper>
            :
            null
    )
}

export default MainBanner;

const EnSmall = (htmlString, zoom = 0.75) => {
    const regex = /style="[^"]*font-size:\s*([^;]+)[^"]*"/g;

    const modifiedHtml = htmlString.replace(regex, (match, fontSize) => {
        const currentFontSize = parseFloat(fontSize);
        const newFontSize = currentFontSize * zoom;
        return `style="font-size: ${newFontSize}px;"`;
    });

    return modifiedHtml;
}


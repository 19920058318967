import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ProductCard from '../product-card/productCard';
import { Link } from '../my-link/link';
import useTranslation from '../../hooks/useTranslation';
import Swiper from "../swiper"


const ProductCards = ({ products, title = "", totalCount, count = 5, url, prodFavData, metaData, storeData }) => {
    const { t } = useTranslation()

    const breakpoints = {
        0: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        465: {
            slidesPerView: 2,
            spaceBetween: 0,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 0,
        },
        1024: {
            slidesPerView: count - 2,
            spaceBetween: 0,
        },
        1440: {
            slidesPerView: 4,
            spaceBetween: 0,
        },
        1600: {
            slidesPerView: count,
            spaceBetween: 0,
        },
    }

    return (
        <Grid className="section" paddingX={0}>

            <Grid container columns={2} marginBottom={"1rem"} sx={{ px: { xs: 3.5, sm: 0, md: 1, lg: 1.5 }, zIndex: 2 }} >
                {
                    totalCount ?
                        <Grid item xs={1}>
                            <h2 style={{ fontWeight: '500', lineHeight: 1 }}>{title}</h2>
                            <span className="light-black">{totalCount} {t("Articles")}</span>
                        </Grid>
                        :
                        null
                }
                <Grid item xs={1} textAlign="right" paddingTop={"1.6rem"} sx={{ mb: 0, textDecoration: "underline", "& a": { py: 1, zIndex: 2 }, zIndex: 2 }} >
                    {url ? <Link to={url} className="light-black">{t("Show all")}</Link> : <span> </span>}
                    <br />
                </Grid>
            </Grid>
            <Grid sx={{
                ".swiper": {
                    pt: "30px", mt: "-30px",
                    "--swiper-scrollbar-top": "4px",
                    "--swiper-scrollbar-size": "7px",
                    "--swiper-navigation-size": "16px",
                    "--swiper-navigation-color": "#000",
                    ".swiper-scrollbar": { display: { xs: "none", md: "block" }, width: "150px", right: "0.6rem", left: "unset" },
                }
            }}>
                <Swiper
                    navigation={true}
                    Autoplay={false}
                    pagination={false}
                    scrollbar={{ enabled: true, draggable: true, snapOnRelease: true }}
                    loop={false}
                    breakpoints={breakpoints}
                    outerNavigation={true}
                >
                    {
                        products.map((item, i) =>
                            <Grid mb={2} key={i.toString()} sx={{ mx: 1.5 }}>
                                <ProductCard
                                    product={item}
                                    category_path="\\"
                                    metaData={metaData}
                                    storeData={storeData}
                                />
                            </Grid>
                        )
                    }
                </Swiper>
            </Grid>
        </Grid>
    )
}

export default ProductCards